const IconSearch = props => (
	<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" {...props}>
		<g clipPath="url(#a)">
			<path
				d="m19.42 18.802-4.81-5c1.226-1.51 1.886-3.302 1.886-5.189C16.496 4.18 12.816.5 8.383.5 3.949.5.27 4.085.27 8.613c0 4.529 3.679 8.113 8.113 8.113 1.698 0 3.302-.471 4.622-1.509l4.812 5c.188.189.471.283.754.283.284 0 .566-.094.755-.283.472-.283.566-1.038.095-1.415ZM8.478 2.575c3.302 0 6.038 2.736 6.038 6.038s-2.736 6.038-6.038 6.038S2.44 11.915 2.44 8.613s2.736-6.038 6.038-6.038Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="currentColor" transform="translate(0 .5)" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default IconSearch;
