import { memo, useRef, useCallback, useState, useContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { messagePropType } from "app/utils/propTypes";
import { useField } from "formik";
import "./AdvancedSelectFormik.scss";
import classNames from "classnames";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

import { Option, MenuList, Menu, DropdownIndicator } from "./componentsOverrides";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import useOnDir, { DIRECTION } from "app/utils/hooks/useOnDir";
const defaultBoxShadow = "0 8px 10px rgba(0, 0, 0, 0.06)";

const getStyles = (
	isMobile,
	isRLT,
	popperWidth,
	mobileMenuWidth,
	menuPortalMargin,
	popperOffset,
	menuMarginTop,
	menuListPadding,
	menuListMaxHeight,
	menuBorder,
	menuBoxShadow
) => ({
	dropdownIndicator: (base, state) => ({
		...base,
		color: "black",
		transition: "all .2s ease",
		transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
	}),
	menuList: base => ({
		...base,
		minWidth: isMobile ? "none" : popperWidth,
		width: isMobile ? mobileMenuWidth : "unset",
		maxHeight: menuListMaxHeight,
		padding: isMobile ? menuListPadding[1] : menuListPadding[0],
	}), // on limite volontairement pour afficher la moitié du 4ème pour suggérer la possibilité de scroller pour afficher la suite
	option: base => ({
		...base,
		border: "none",
		height: isMobile ? "auto" : "56px",
		display: "flex",
		alignItems: "center",
	}),
	menuPortal: provided => ({
		...provided,
		zIndex: 20,
		marginTop: menuPortalMargin,
	}),
	menu: provided => ({
		...provided,
		width: "fit-content",
		boxShadow: menuBoxShadow || defaultBoxShadow,
		borderRadius: "none",
		...(isRLT
			? {
					right: popperOffset[0] || 0,
			  }
			: {
					left: popperOffset[0] || 0,
			  }),
		top: popperOffset[1] ?? 0,
		marginTop: menuMarginTop,
		border: menuBorder,
	}),
});

const AdvancedSelectFormik = ({
	id,
	selectRef = {},
	label,
	className,
	isRequired,
	isSearchable = true,
	icon,
	keyCountMinToOpenMenu = 0,
	popperWidth = 200,
	components,
	menuPortalMargin = 20,
	menuMarginTop = 23,
	captureMenuScroll = true,
	menuShouldBlockScroll = false,
	loading,
	menuListMaxHeight = 474,
	name,
	onFocus = () => {},
	popperOffset = [],
	menuListPadding = ["8px 0", "0"],
	isAutoScrollActive,
	menuBorder = "none",
	menuBoxShadow,
	...restProps
}) => {
	const direction = useOnDir();
	const [field, meta, helpers] = useField(name);
	const { setValue, setTouched, setError } = helpers;
	const [targetValue, setTargetValue] = useState("");
	const { resolution } = useContext(AppGlobalsContext);

	const isRLT = direction === DIRECTION.RTL;
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const inputRef = useRef();
	const mobileMenuWidth = inputRef?.current?.offsetWidth;
	const [isSelectFocused, setIsSelectFocused] = useState(false);

	const isTouched = (meta.touched && (!meta.error || targetValue !== "")) || field.value;

	const inputClassName = useMemo(
		() =>
			classNames("advanced-select", className, {
				"advanced-select--required": isRequired,
				"advanced-select--touched": isTouched,
				"advanced-select--error": meta.touched && meta.error,
				"advanced-select--with-icon": icon,
			}),
		[className, isRequired, isTouched, meta.touched, meta.error, icon]
	);

	const handleFocus = useCallback(() => {
		if (isSearchable) {
			setIsSelectFocused(true);
		}
		setTouched(true);
		setError();
		onFocus();
	}, [isSearchable, setTouched, setError]);

	const setUntouchedOnBlur = useCallback(
		event => {
			if (isSearchable) {
				setIsSelectFocused(false);
			}

			// quand le user clique ailleurs  et le champ est vide (il a rien sélectionné) ou que event.target.value est vide (il a rien tapé)
			// on met à jour la valuer de TargetValue et on set touched à false car ce dernier ne contient pas de value
			if (event.target.value === "" || !field.value) {
				setTargetValue("");
				setTouched(false);
			}
		},
		[field.value, isSearchable, setTouched]
	);

	const handleChange = useCallback(option => {
		setValue(option);
	}, []);

	const [menuIsOpen, setMenuOpen] = useState(false);

	const handleInputChange = useCallback(values => {
		setMenuOpen(values.length >= keyCountMinToOpenMenu);
	}, []);

	if (keyCountMinToOpenMenu > 0) {
		restProps.menuIsOpen = menuIsOpen;
		restProps.onInputChange = handleInputChange;
	}
	const handleKeyDown = event => {
		setTargetValue(event.target.value);
	};

	const handleMenuOpen = useCallback(() => {
		setMenuOpen(true);
	}, []);

	const handleScroll = useCallback(() => {
		if (inputRef?.current && selectRef?.current && !isAutoScrollActive) {
			const menuRect = selectRef.current.menuListRef?.getBoundingClientRect();

			if (menuRect) {
				const halfMenuHeight = menuRect.height / 2;
				const menuMidpoint = menuRect.top + halfMenuHeight;

				const isHalfMenuVisible = menuMidpoint > 0 && menuMidpoint < window.innerHeight;

				// If the midpoint of the menu is out of view, close the menu
				if (!isHalfMenuVisible && menuIsOpen) {
					setMenuOpen(false);
					simulateOnBlurEvent();
				}
			}
		}
	}, [menuIsOpen, inputRef?.current, selectRef?.current, isAutoScrollActive]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	const simulateOnBlurEvent = useCallback(() => {
		if (selectRef?.current) {
			selectRef.current.blur();
		}
	}, [selectRef?.current]);

	const styles = useMemo(
		() =>
			getStyles(
				isMobile,
				isRLT,
				popperWidth,
				mobileMenuWidth,
				menuPortalMargin,
				popperOffset,
				menuMarginTop,
				menuListPadding,
				menuListMaxHeight,
				menuBorder,
				menuBoxShadow
			),
		[
			isMobile,
			isRLT,
			popperWidth,
			mobileMenuWidth,
			menuPortalMargin,
			popperOffset,
			menuMarginTop,
			menuListPadding,
			menuListMaxHeight,
			menuBorder,
			menuBoxShadow,
		]
	);

	const handleMenuClose = useCallback(() => {
		setMenuOpen(false);
	}, []);

	return (
		<div
			ref={inputRef}
			className={inputClassName}
			data-testid={restProps["data-testid"]}
			id={id}
		>
			{!loading ? (
				<>
					<Select
						ref={selectRef}
						isClearable={isSearchable && isSelectFocused}
						backspaceRemovesValue={true}
						className="advanced-select__select"
						classNamePrefix="advanced-select__select"
						classNames={{
							menuPortal: () => "test",
						}}
						styles={styles}
						placeholder={null}
						blurInputOnSelect={true}
						captureMenuScroll={captureMenuScroll}
						// This prop needs to be kept as false to disable auto scrolling
						menuShouldScrollIntoView={false}
						menuShouldBlockScroll={menuShouldBlockScroll}
						{...field}
						onFocus={handleFocus}
						onBlur={setUntouchedOnBlur}
						onChange={handleChange}
						menuPortalTarget={typeof document !== "undefined" && document.body}
						components={{ Menu, MenuList, Option, DropdownIndicator, ...components }}
						isSearchable={isSearchable}
						onKeyDown={handleKeyDown}
						onMenuOpen={handleMenuOpen}
						onMenuClose={handleMenuClose}
						{...restProps}
					/>
					{icon && <div className="advanced-select__icon">{icon}</div>}
					<label htmlFor={id} className="advanced-select__label">
						{isTouched ? (
							<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
								{label}
							</Typography>
						) : (
							<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>{label}</Typography>
						)}
					</label>
				</>
			) : (
				<div className="advanced-select__loader">
					<LoaderBar height={10} width={"80%"} />
					<LoaderBar height={10} />
				</div>
			)}
		</div>
	);
};

AdvancedSelectFormik.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	getOptionValue: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	onFocus: PropTypes.func,
	onMenuClose: PropTypes.func,
	isRequired: PropTypes.bool,
	icon: PropTypes.element,
	keyCountMinToOpenMenu: PropTypes.number,
	popperWidth: PropTypes.number,
	components: PropTypes.object,
	menuPortalMargin: PropTypes.number,
	selectRef: PropTypes.object,
	captureMenuScroll: PropTypes.bool,
	menuShouldBlockScroll: PropTypes.bool,
	loading: PropTypes.bool,
	menuListPadding: PropTypes.array,
	menuMarginTop: PropTypes.number,
	name: PropTypes.string,
	menuListMaxHeight: PropTypes.number,
	menuBoxShadow: PropTypes.string,
	menuBorder: PropTypes.string,
	isAutoScrollActive: PropTypes.bool,
	popperOffset: PropTypes.array,
};

export default memo(AdvancedSelectFormik);
