import { useCallback } from "react";
import { useField } from "formik";
import "./radio.scss";
import classNames from "classnames";
import IconRadioChecked from "app/pages/.shared/static/icons/IconRadioChecked";
import IconRadioUnchecked from "app/pages/.shared/static/icons/IconRadioUnchecked";
import PropTypes from "prop-types";

const RadioInput = ({
	children,
	disabled,
	showIcon = false,
	body,
	id,
	onUpdate,
	canUnselect,
	...props
}) => {
	const [field, meta, helpers] = useField({ ...props, type: "radio" });

	const radioClassName = props.className
		? props.className
		: classNames({
				radio: true,
				"radio--checked": field.checked,
				"radio--error": meta.touched && meta.error,
				"radio--disabled": disabled,
		  });

	const iconNode = showIcon && (
		<div className="radio__icon">
			{field.checked ? (
				<IconRadioChecked width={15} height={15} />
			) : (
				<IconRadioUnchecked width={15} height={15} />
			)}
		</div>
	);

	const onChange = useCallback(
		event => {
			field.onChange(event);
			onUpdate(field.value, field.name);
		},
		[field.value, field.name]
	);

	const handleClick = useCallback(
		event => {
			if (field.checked) {
				helpers.setValue("");
				field.onChange({
					...event,
					target: {
						...event.target,
						checked: false,
						value: "",
					},
				});
				onUpdate("", field.name);
			}
		},
		[field.checked, field.name]
	);

	const bodyNode = body ? <div className="radio__content">{body}</div> : false;

	return (
		<div className={radioClassName} data-testid={props["data-testid"]}>
			<input
				id={id}
				type="radio"
				className="radio__input"
				{...field}
				onClick={canUnselect ? handleClick : undefined}
				onChange={onChange}
				{...props}
			/>
			<label className="radio__label" htmlFor={id}>
				<div className="radio__header">
					{iconNode}
					<div className="radio__text">{children}</div>
				</div>
				{bodyNode}
			</label>
		</div>
	);
};

RadioInput.propTypes = {
	canUnselect: PropTypes.bool,
	disabled: PropTypes.bool,
	showIcon: PropTypes.bool,
	checked: PropTypes.bool,
	onUpdate: PropTypes.func,
	["data-testid"]: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.string.isRequired,
	body: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool]),
};

RadioInput.defaultProps = {
	onUpdate: () => {},
};

export default RadioInput;
