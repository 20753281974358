import { components } from "react-select";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<IconArrowDown height={12} width={12} />
		</components.DropdownIndicator>
	);
};

export default DropdownIndicator;
